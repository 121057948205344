import * as React from "react"
import Seo from "../components/seo"
import * as notFoundStyles from "./404.module.scss"

const NotFoundPage = () => (
  <div className={notFoundStyles.wrapper}>
    <Seo title="404: Not found" />
    <div className={notFoundStyles.redLine}></div>
    <h1 className={notFoundStyles.notFound}>404: Not Found</h1>
    <p className={notFoundStyles.route}>
      You just hit a route that doesn&#39;t exist... try one of the links above.
    </p>
  </div>
)

export default NotFoundPage
